import React, { useState } from "react";
import {
  Container,
  InputLabel,
  Box,
  FormControl,
  Select,
  MenuItem,
  AlertTitle,
  Alert
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fileReportDownload } from "../../../utils/fileReportDownload";
import { dateToFormat } from "../../../utils/dateParsing";
import es from "date-fns/locale/es";

const REPORTS_TYPE = [
  { name: 'Atenciones', url: '/subred_sur_occidente_xls_export/attentions', file: 'atenciones', version:process.env.REACT_APP_VERSION_2},
  { name: 'Insumos TAB', url: '/subred_sur_occidente_xls_export/supplies_tab', file: 'insumos-TAB', version:process.env.REACT_APP_VERSION_1},
  { name: 'Insumos TAM ', url: '/subred_sur_occidente_xls_export/supplies_tam', file: 'atenciones', version:process.env.REACT_APP_VERSION_1},
  { name: 'Medicamentos de control TAM', url: '/subred_sur_occidente_xls_export/control_medicines_tam', file: 'atenciones', version:process.env.REACT_APP_VERSION_1},
  { name: 'Medicamentos TAM', url: '/subred_sur_occidente_xls_export/medicines_tam', file: 'atenciones', version:process.env.REACT_APP_VERSION_1},
  { name: 'Furtran', url: '/subred_sur_occidente_xls_export/furtran', file: 'furtran', version:process.env.REACT_APP_VERSION_1 },
  { name: 'Humedad relativa PDF', url: '/aph_sur_occidente_pdf/relative_humidity_report', file: 'humedad-relativa', showAmbulanceField: true, isPDF:true, version:'' },
  { name: 'Elementos de protección PDF', url: '/aph_sur_occidente_pdf/protection_elements_tab_report', file: 'elementos-de-protección', showAmbulanceField: true, isPDF:true, version:'' },
  { name: 'Desinfección PDF', url: '/aph_sur_occidente_pdf/desinfection_report', file: 'desinfeccion', showAmbulanceField: true, isPDF:true, version:'' },
  { name: 'Inconsistencias', url: '/subred_sur_occidente_xls_export/export_inconsistencies', file: 'inconsistencias', version:process.env.REACT_APP_VERSION_2 },
  { name: 'Inspección de vehículos', url: '/aph_sur_occidente_pdf/export_vehicle_inspection', file: 'inspeccion-vehiculos', showAmbulanceField: true, isPDF:true, version:'' },
  { name: 'Inventario de ambulancia', url: '/aph_sur_occidente_pdf/export_ambulance_inventory', file: 'inventario-ambulancia', showAmbulanceField: true, isPDF:true, version:'' },
];
const AMBULANCE_LIST = [
  'OLN253',
  'JQV141',
  'JQV134',
  'JQV139',
  'JQV138',
  'JQV142',
  'JQV131',
  'JQV124',
  'JQV133',
  'OLN247',
  'JQV118',
  'JQV121',
  'JQV130',
  'JQV132',
  'JQV140',
  'JQV122',
  'JQV152',
  'JQV120',
  'JQV153',
  'JQV126',
  'JQV129',
  'JQV098',
  'JQV123',
  'JQV125',
  'JQV127',
  'JQV137',
  'JQV128',
  'JQV135',
  'JQV119',
  'JQV136',
  'JQV228',
  'LIS774',
  'OLN251',
  'OLN244',
  'OLN248'
];

export const APHSurOccidenteReport = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [ambulanceSelected, setAmbulanceSelected] = useState('');
  const [filterType, setFilterType] = useState(REPORTS_TYPE[0]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});

  registerLocale("es", es);

  const handleSubmit = (event) => {
    event.preventDefault();
    requestReport();
  };

  const requestReport = async () => {
    try {
      const params = {
        fromDate: dateToFormat(fromDate, 'YYYY/MM/DD HH:mm'),
        untilDate: dateToFormat(toDate, 'YYYY/MM/DD HH:mm'),
        ambulance: ambulanceSelected
      }
      setShowAlert(false);
      setLoading(true);
      const extention = filterType.isPDF?'pdf':'xlsx'
      await fileReportDownload(filterType.url, params, `${filterType.file}.${extention}`,filterType.version,filterType.isPDF);
      setLoading(false);
    } catch (error) {
      console.log("error -> ", error);
      if (error?.response?.status === 406) { //range bad
        setAlertInfo({
          title: "Error",
          message: "Rango de fecha excedido",
          action: "Cambia el rango de fechas",
        });
        setShowAlert(true);
      } else if (error?.response?.status === 400) { // rango no válido
        setAlertInfo({
          title: "Error",
          message: "El rango de fecha no es válido",
          action: "Valida que el rango sea correcto",
        });
        setShowAlert(true);
      } else if (error?.response?.status === 0) {
        setAlertInfo({
          title: "Timeout",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlert(true);
      } else {
        setAlertInfo({
          title: "Error del servidor",
          message: "Ocurrio un error procesando",
          action: "Intenta reduciendo el rango de fechas",
        });
        setShowAlert(true);
      }
      setLoading(false);
    }
  };

  const handleChangeSelect = (event) => {
    event.preventDefault();
    setFilterType(event.target.value);
  };

  const handleChangeAmbulanceSelect = (event) => {
    event.preventDefault();
    setAmbulanceSelected(event.target.value)
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {showAlert && (
            <Alert onClose={() => setShowAlert(false)} severity="error">
              <AlertTitle>{alertInfo?.title}</AlertTitle>
              {alertInfo?.message} — <strong>{alertInfo?.action}</strong>
            </Alert>
          )}

          <InputLabel>Desde</InputLabel>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={fromDate}
              onChange={setFromDate}
              showTimeSelect
              name="fromDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <InputLabel>Hasta</InputLabel>
          <div style={{ display: "flex" }}>
            <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              selected={toDate}
              onChange={setToDate}
              showTimeSelect
              name="toDate"
              locale="es"
              className="react-datetimepicker-custom"
            />
          </div>

          <br />
          <FormControl fullWidth>
            <InputLabel id="filtertype-select-label">Filtrar Por</InputLabel>
            <Select
              labelId="filtertype-select-label"
              id="filtertype-select"
              value={filterType}
              label="filterType"
              onChange={handleChangeSelect}
              name="filtertype-select"
            >
              {
                REPORTS_TYPE.map((item, key) => <MenuItem value={item}>{item.name}</MenuItem>)
              }
            </Select>
          </FormControl>

          {
            filterType.showAmbulanceField ?
            <>
              <br/>
              <br/>
              <FormControl fullWidth>
                <InputLabel id="filtertype-ambulance-label">Seleccione la placa</InputLabel>
                <Select
                  labelId="filtertype-ambulance-label"
                  id="filtertype-ambulance"
                  value={ambulanceSelected}
                  label="Seleccione la placa"
                  onChange={handleChangeAmbulanceSelect}
                  name="filtertype-ambulance"
                >
                  {
                    AMBULANCE_LIST.map((item, key) => <MenuItem key={key+item} value={item}>{item}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </>
              : null
          }

          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Generar Reporte
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};
